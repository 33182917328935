/*
 * Base structure
 */

html,
body {
    height: 100%;
    background-color: #333;
    overflow: hidden;
}

body {
    color: #fff;
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, .5);
    font-size: 15px;
    margin: 0;
}

.h1 {
    text-shadow: 0 0.1rem .1rem rgb(0 0 0 / 50%), 0 0.1rem .3rem rgb(0 0 0 / 50%)
}

*:focus {
    outline: none;
}

a {
    color: #00a5ff;
}

a:hover {
    color: #eeeeee;
}

label {
    font-size: 13px;
    font-weight: lighter;
    padding: 0;
    margin: 0;
    text-shadow: none;
    color: #aaaaaa;
}

button, input, textarea, select {
    background: transparent;
    padding: 3px 6px 3px 6px;
    font-size: 13px;
    // prevent "overfilling" container by using width:100%
    width: 100%;
    margin: 1px 0 1px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, .5);
    overflow: hidden;
}

button {
    background: rgba(0, 165, 255, 0.03);
    color: #00a5ff;
}

input, textarea, select {
    background: rgba(255, 165, 0, 0.03);
    color: #c0c0c0;
}

button:hover {
    color: #eeeeee;
    background: rgba(0, 165, 255, 0.1);
}

button:active {
    color: #eeeeee;
    background: rgba(0, 165, 255, 0.2);
}

input:hover {
    color: #eeeeee;
    background: rgba(255, 165, 0, 0.1);
}

#client-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-background-dark {
    background-color: #363636;
    box-shadow: inset 0 0 .2rem rgba(0, 0, 0, .5);
}

.client-background-light {
    background-color: #393939;
    box-shadow: inset 0 0 .5rem rgba(0, 0, 0, .5);
}

.client-background-full {
    background-color: #A06030;
}

.client-background-frame {
    background-color: #505050;
}

.client-background {
    background-color: #808080;
}

.client-color-text {
    color: #808080;
}

.client-color-info {
    color: #00a5ff;
}

.client-color-success {
    color: #008000;
}

.client-color-error {
    color: #800000;
}

.client-color-warning {
    color: #ffa500;
}

.client-border {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(top left, transparent 20%, #707070 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, transparent 20%, #707070 100%);
    border-image-source: linear-gradient(to bottom left, transparent 20%, #707070 100%);
    border-image-slice: 1;
}

.client-border-frame {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(top left, transparent 20%, #505050 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, transparent 20%, #505050 100%);
    border-image-source: linear-gradient(to top right, transparent 20%, #505050 100%);
    border-image-slice: 1;
}

.client-border-full {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(135deg, transparent 10%, #A06030 20%, #A06030 80%, transparent 90%);
    -webkit-border-image: -webkit-linear-gradient(135deg, transparent 10%, #A06030 20%, #A06030 80%, transparent 90%);
    border-image-source: linear-gradient(135deg, transparent 10%, #A06030 20%, #A06030 80%, transparent 90%);
    border-image-slice: 1;
}

input, .client-border-input {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(top left, transparent 20%, #ffa500 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, transparent 20%, #ffa500 100%);
    border-image-source: linear-gradient(to bottom, transparent 20%, #ffa500 100%);
    border-image-slice: 1;
}

button, .client-border-button {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(top left, transparent 0%, #00a5ff 100%);
    -webkit-border-image: -webkit-linear-gradient(top left, transparent 0%, #00a5ff 100%);
    border-image-source: linear-gradient(to top, transparent 0%, #00a5ff 100%);
    border-image-slice: 1;
}

.client-ruler-bottom {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(180deg, transparent 60%, #808080 100%);
    -webkit-border-image: -webkit-linear-gradient(180deg, transparent 60%, #808080 100%);
    border-image-source: linear-gradient(180deg, transparent 60%, #808080 100%);
    border-image-slice: 1;
}

.client-ruler-top {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: -moz-linear-gradient(0deg, transparent 60%, #666666 100%);
    -webkit-border-image: -webkit-linear-gradient(0deg, transparent 60%, #666666 100%);
    border-image-source: linear-gradient(0deg, transparent 60%, #666666 100%);
    border-image-slice: 1;
}

.client-flash {
    opacity: 1;
    animation: client-flash 1.5s normal infinite ease-in-out;
}

@keyframes client-flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
