// Source: https://loading.io/css/
// Integration: <div class="lds-ellipsis" style="width: 36px; height: 9px;"><div class="client-border-full"></div><div class="client-border-full"></div><div class="client-border-full"></div><div class="client-border-full"></div></div>
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 9px;
}
.lds-ellipsis div {
    position: absolute;
    //top: 33px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    // Use client-background- classes in div!
    //background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 14px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 26px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(12px, 0);
    }
}
