// Source: https://loading.io/css/
// Integration: <div class="lds-ripple" style="width: 18px; height: 18px;"><div class="client-border-full"></div><div class="client-border-input"></div></div>
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
}
.lds-ripple div {
    position: absolute;
    // Use client-border- classes in div!
    //border: 1px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 9px;
        left: 9px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        opacity: 0;
    }
}
